import { apps } from './apps';

export const environment = {
  environment: 'development',
  production: true,
  appDomain: 'umami-ware.dev',
  appUrl: 'https://view.umami-ware.dev',
  apiUrl: 'https://isa-api.umami-ware.dev',
  integrationsApiUrl: 'https://integrations-api.umami-ware.dev',
  workSchedulerApi: 'https://work-scheduler-api.umami-ware.dev',
  spaceUrl: 'https://isadev.blob.core.windows.net',
  msAutosaveDebounceTime: 2000,
  googleMapsKey: 'AIzaSyD_0ZSVH1L0Dn80itPWkk-JLBW5he81auY',
  defaultTheme: 'light-blue',
  googleTagManagerKey: 'GTM-56F2J36',
  // userBackKey: '29834|43651|WOjV9G1GukoQVl70cgggNrVhN',
  userBackKey: '29834|71480|WOjV9G1GukoQVl70cgggNrVhN',
  userBackPortalUrl: 'https://umamiviewtest.ideas.userback.io/p/0IQq2ED5MQ',
  intercomAppId: 'zgntane1',
  supportPhoneNumber: '+49 9904 9689489',
  notificationsCenterUri: 'https://notifications-api.umami-ware.dev',
  keycloak: {
    url: 'https://auth.umami-ware.dev',
    realm: 'isa',
    clientId: 'isa',
  },
  agGridKey:
    'CompanyName=Umami Ware GmbH,LicensedGroup=ISA,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-038931,SupportServicesEnd=2_March_2024_[v2]_MTcwOTMzNzYwMDAwMA==b0f40c1f73f40107ce4667cb2895dcc3',
  apps: {
    isa: {
      ...apps.isa,
      host: 'https://view.umami-ware.dev',
    },
    glasses: {
      ...apps.glasses,
      host: 'https://glasses.umami-ware.dev',
    },
    visionProfile: {
      ...apps.visionProfile,
      host: 'https://vision-profile.umami-ware.dev',
    },
    caseHistory: {
      ...apps.caseHistory,
      host: 'https://case-history.umami-ware.dev',
    },
    measures: {
      ...apps.measures,
      actionButtonComponent: 'CreateActionButtonComponent',
      embeddedActionIcon: undefined,
      embeddedActionParam: undefined,
      host: 'https://measures.umami-ware.dev',
    },
    integrations: {
      ...apps.integrations,
      host: 'https://integrations.umami-ware.dev',
    },
    calendar: {
      ...apps.calendar,
      host: 'https://calendar.umami-ware.dev',
    },
    workspace: {
      ...apps.workspace,
      host: 'https://workspace.umami-ware.dev',
    },
    messages: {
      ...apps.messages,
      host: 'https://messages.umami-ware.dev',
    },
    marketing: {
      ...apps.marketing,
      host: 'https://marketing.umami-ware.dev',
    },
    payments: {
      ...apps.payments,
      host: 'https://payments.umami-ware.dev',
    },
    analytics: {
      ...apps.analytics,
      host: 'https://analytics.umami-ware.dev',
    },
    products: {
      ...apps.products,
      actionButtonComponent: 'CreateActionButtonComponent',
      host: 'https://products.umami-ware.dev',
    },
    times: {
      ...apps.times,
      host: 'https://times.umami-ware.dev',
    },
    migrate: {
      ...apps.migrate,
      host: 'https://migrate.umami-ware.dev',
    },
    catalogs: {
      ...apps.catalogs,
      host: 'https://catalogs.umami-ware.dev',
    },
    'business-activity': {
      ...apps['business-activity'],
      host: 'https://business-activity.umami-ware.dev',
    },
  },
  teamviewerApiUrl: 'https://webapi.teamviewer.com/api/v1/sessions',
  teamviewerApiKey: '24345510-28Ms20ZnETGIUsweJ66R',
};
